/* HomePage.css */
/* Fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0; /* Start completely transparent */
  }
  to {
    opacity: 1; /* Fully visible */
  }
}

.HomePage {
  animation: fadeIn 1.5s ease-in;
  opacity: 1;
  background-image: url('/images/background.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* Dark overlay effect */
.HomePage::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Dark semi-transparent overlay */
  animation: fadeOutOverlay 1.5s ease-in forwards;
  pointer-events: none; /* Ensure overlay doesn’t interfere with interactions */
  z-index: 1; /* Place the overlay above the background but below content */
}

/* Animation for the overlay */
@keyframes fadeOutOverlay {
  from {
    opacity: 1; /* Fully visible at the start */
  }
  to {
    opacity: 0; /* Fade out completely */
  }
}


.home-content {
  animation: fadeIn 1.5s ease-in;
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  position: relative;
  z-index: 2;
}

.home-content h1 {
  animation: fadeIn 1s ease-in;
  font-size: 3em;
  margin-bottom: 20px;
}

.home-content p {
  animation: fadeIn 1s ease-in;
  animation-delay: 0.5s;
  font-size: 1.5em;
  margin-bottom: 20px;
}

.home-nav {
  margin-top: 20px;
}

.home-link {
  animation: fadeIn 1s ease-in;
  animation-delay: 1s;
  margin: 0 10px;
  text-decoration: none;
  color: #333;
  font-weight: bold;
}

.home-link:hover {
  text-decoration: underline;
}
