/* ComicViewer.css */
/* ComicViewer.css */
/* Rectangle container */
.rectangle-container {
  background-color: rgba(255, 255, 255, 0.8); /* White with 80% opacity */
  border-radius: 15px; /* Rounded corners */
  padding: 20px; /* Space inside the rectangle */
  display: inline-block; /* Size adjusts to content */
  text-align: center; /* Center-align the text and buttons */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for a 3D effect */
}


.nav-buttons {
  margin-bottom: 20px;
}

.back-button {
  display: inline-block;
  padding: 10px 15px;
  background-color: #333;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1em;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: #555;
}


.ComicViewer {
  min-height: 100vh; /* Cover the entire viewport height */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  /* Add a background image or color */
  background-image: url('/images/background.jpg'); /* Update this path as needed */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  /* Optional: Use a background color */
  /* background-color: #f0f0f0; */
}

.ComicViewer h1 {
  margin-bottom: 20px;
  color: #333; /* Ensure visibility against the background */
}

.ComicViewer button {
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
}

.ComicViewer button:hover {
  background-color: #555;
}
/* Disable drag and selection */
.comic-image {
  -webkit-user-drag: none; /* Prevent dragging on Chrome/Safari */
  user-drag: none;         /* Prevent dragging on modern browsers */
  -webkit-user-select: none; /* Disable selection */
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: auto;
}
